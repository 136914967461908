import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <Helmet>
        <title>About Us | All Hair Clinics</title>
        <meta name="description" content="Learn about All Hair Clinics, the world's first independent research organization providing unbiased reviews of hair clinics and hair transplantation services worldwide." />
        <link rel="canonical" href="https://allhairclinics.com/about" />
      </Helmet>
      <h1 className="visually-hidden">About All Hair Clinics</h1>
      <h2 className="page-title">About Us</h2>
      <div className="content-section">
        <h3>Welcome to All Hair Clinics</h3>
        <p>All Hair Clinics is the world's first independent research organization dedicated to providing unbiased reviews of <Link to="/clinics" className="hidden-link">hair clinics</Link> and hair transplantation services worldwide. As a non-profit organization, our mission is to deliver accurate, reliable, and up-to-date information in the complex field of hair restoration.</p>
        <p>Navigating the myriad of options for hair transplantation can be overwhelming. With countless clinics and procedures available globally, making an informed decision is crucial. We are committed to simplifying this process by offering comprehensive reviews and expert insights into hair clinics around the world. Our goal is to be your trusted source for all things related to hair restoration and hair transplant services.</p>
      </div>
      <div className="content-section">
        <h3>Our Mission</h3>
        <ul>
          <li><strong>Unbiased Reviews:</strong> We provide honest and impartial reviews of hair clinics, ensuring that you receive trustworthy information to make informed decisions.</li>
          <li><strong>Global Coverage:</strong> Our database includes hair clinics from all over the world, giving you access to a wide range of options regardless of your location.</li>
          <li><strong>Community Contributions:</strong> We welcome input from both doctors and patients. By sharing clinic listings and personal experiences, our community becomes richer and more informative.</li>
        </ul>
      </div>
      <div className="content-section">
        <h3>Why Choose Us?</h3>
        <ul>
          <li><strong>Independent and Non-Profit:</strong> Being a non-profit organization allows us to operate without commercial bias. Our primary focus is on delivering value to you, not on generating profit.</li>
          <li><strong>Expertise in Hair Restoration:</strong> Our team is dedicated to researching and understanding the latest advancements in hair transplantation, ensuring that you have access to the most current information.</li>
          <li><strong>Transparent and Trustworthy:</strong> We do not sell paid reviews. All the reviews and information on our platform are genuine contributions from real patients and professionals.</li>
        </ul>
      </div>
      <div className="content-section">
        <h3>Get Involved</h3>
        <p>We believe in the power of community and the importance of shared experiences. If you're a doctor specializing in hair transplantation, we invite you to <Link to="/list-your-clinic" className="hidden-link">list your clinic</Link> and contribute to our growing database. Patients who have undergone hair restoration procedures are encouraged to share their stories and reviews. Your insights can guide others on their hair restoration journey.</p>
      </div>
      <div className="content-section">
        <h3>Our Commitment to You</h3>
        <p>Your trust is our top priority. We are dedicated to maintaining a platform that values transparency, integrity, and accuracy. By choosing All Hair Clinics, you're partnering with an organization that puts your needs first.</p>
      </div>
      <div className="content-section">
        <h3>Contact Us</h3>
        <p>Have questions or need more information? <Link to="/contact" className="hidden-link">Reach out to us</Link>. We're here to assist you every step of the way.</p>
      </div>
      <div className="content-section">
        <h3>Explore and Learn</h3>
        <p>Dive into our extensive resources to learn more about:</p>
        <ul>
          <li><Link to="/hair-transplant-techniques" className="hidden-link">Hair transplantation techniques</Link></li>
          <li><Link to="/choosing-a-clinic" className="hidden-link">Choosing the right hair clinic</Link></li>
          <li><Link to="/testimonials" className="hidden-link">Patient testimonials and success stories</Link></li>
          <li><Link to="/latest-advancements" className="hidden-link">Latest advancements in hair restoration technology</Link></li>
          <li><Link to="/post-transplant-care" className="hidden-link">Tips for post-transplant care</Link></li>
        </ul>
      </div>
      <div className="content-section">
        <h3>Join Our Community</h3>
        <p>Stay connected with us through our <Link to="/newsletter" className="hidden-link">newsletter</Link> and social media channels. Be the first to know about new clinic listings, recent reviews, and the latest news in hair restoration.</p>
      </div>
      <div className="content-section">
        <p>At All Hair Clinics, we're more than just a review platform; we're a community dedicated to helping you regain confidence and make informed decisions about your hair restoration journey.</p>
      </div>
      <div className="hidden-links">
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-of-service">Terms of Service</Link>
        <Link to="/sitemap">Sitemap</Link>
      </div>
    </div>
  );
};

export default About;