import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import clinicsPart1 from '../data/clinics_part1.json';
import clinicsPart2 from '../data/clinics_part2.json';
import './Clinics.css';

const Clinics = () => {
  const [clinics, setClinics] = useState([]);

  useEffect(() => {
    const allClinics = [...clinicsPart1, ...clinicsPart2];
    setClinics(allClinics);
  }, []);

  const formatClinicName = (name) => {
    return name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  return (
    <div className="clinics-page">
      <Helmet>
        <title>Hair Transplant Clinics | Comprehensive List</title>
        <meta name="description" content="Explore our comprehensive list of hair transplant clinics. Find detailed information and compare top clinics for your hair restoration needs." />
        <link rel="canonical" href="https://allhairclinics.com/clinics" />
      </Helmet>
      <h1 className="page-title">Hair Transplant Clinics</h1>
      <div className="clinic-grid">
        {clinics.map((clinic, index) => (
          <Link to={`/clinic/${formatClinicName(clinic.name)}`} key={index} className="clinic-card">
            <h2 className="clinic-name">{clinic.name}</h2>
            <p className="clinic-location">{clinic.location}</p>
            <div className="clinic-details">
              <p>Price Range: {clinic.priceRange}</p>
              <p>Techniques: {clinic.techniquesUsed.join(', ')}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Clinics;