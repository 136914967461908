import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './BlogList.css';
import the_role_of_hair_transplant_surgeons_qualification from './the-role-of-hair-transplant-surgeons-qualification';
import the_future_of_hair_transplants_emerging_technologies from './the-future-of-hair-transplants-emerging-technologies';
import advancements_in_fue_techniques_a_game_changer_in_hair_transplantation from './advancements-in-fue-techniques-a-game-changer-in-hair-transplantation';

const BlogList = () => {
  const blogPosts = [
    { title: 'The Role of Hair Transplant Surgeons: Qualifications and Expertise', author: 'Anonymous', date: '2024-10-05', component: the_role_of_hair_transplant_surgeons_qualification, path: 'the-role-of-hair-transplant-surgeons-qualification' },
    { title: 'Advancements in FUE Techniques: A Game-Changer in Hair Transplantation', author: 'Dr. Jane Smith', date: '2024-10-05', component: advancements_in_fue_techniques_a_game_changer_in_hair_transplantation, path: 'advancements-in-fue-techniques-a-game-changer-in-hair-transplantation' },
    { title: 'The Future of Hair Transplants: Emerging Technologies', author: 'John Doe', date: '2024-10-05', component: the_future_of_hair_transplants_emerging_technologies, path: 'the-future-of-hair-transplants-emerging-technologies' },
    { title: "The Benefits of Hair Transplants for Men and Women", path: "benefits-of-hair-transplants" },
    { title: "Hair Transplant in Turkey: What You Need to Know", path: "hair-transplant-turkey-guide" },
    { title: "What to Expect During Your Hair Transplant Consultation", path: "hair-transplant-consultation" },
    { title: "A Comprehensive Guide to Hair Transplant Recovery", path: "hair-transplant-recovery-guide" },
    { title: "Cost Comparison: Hair Transplants in Different Countries", path: "hair-transplant-cost-comparison" },
    { title: "The Science Behind Hair Restoration: How Hair Transplants Work", path: "hair-transplant-science" },
    { title: "Hair Transplant Myths Debunked: What You Need to Know", path: "hair-transplant-myths-debunked" },
    { title: "Choosing the Right Hair Transplant Clinic: Key Factors to Consider", path: "choosing-right-hair-transplant-clinic" }
  ];

  return (
    <div className="blog-list">
      <Helmet>
        <title>Hair Transplant Blog | All Hair Clinics</title>
        <meta name="description" content="Explore our blog for in-depth articles on hair transplants, hair loss causes, treatment options, and the latest advancements in hair restoration." />
        <meta name="keywords" content="hair transplant, hair loss, hair restoration, blog, articles" />
        <link rel="canonical" href="https://allhairclinics.com/blog" />
      </Helmet>
      <h1 className="visually-hidden">Hair Transplant Blog Articles</h1>
      <h2 className="blog-list-title">Hair Transplant Blog</h2>
      <p className="blog-list-intro">Explore our collection of articles covering various aspects of hair transplants, from choosing the right clinic to understanding the latest technologies in hair restoration.</p>
      <nav aria-label="Blog posts">
        <ul className="blog-post-list">
          {blogPosts.map((post, index) => (
            <li key={index} className="blog-post-item">
              <Link to={`/blog/${post.path}`} className="blog-post-link">
                {post.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="hidden-links">
        <Link to="/clinics">Hair Transplant Clinics</Link>
        <Link to="/about">About All Hair Clinics</Link>
        <Link to="/reviews">Hair Transplant Reviews</Link>
        <Link to="/contact">Contact Us</Link>
      </div>
    </div>
  );
};

export default BlogList;