import React, { useState } from 'react';
import { FaSearch, FaDollarSign, FaCut, FaCertificate } from 'react-icons/fa';

const styles = {
  searchTool: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '30px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  form: {
    display: 'grid',
    gap: '25px',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '8px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  },
  input: {
    padding: '12px',
    borderRadius: '5px',
    border: '1px solid #ced4da',
    fontSize: '16px',
    transition: 'border-color 0.3s ease',
  },
  checkboxGroup: {
    border: 'none',
    padding: '0',
  },
  checkboxContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f8f9fa',
    borderRadius: '20px',
    padding: '8px 15px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    fontSize: '14px',
  },
  buttonGroup: {
    display: 'flex',
    gap: '15px',
    justifyContent: 'center',
  },
  button: {
    padding: '12px 25px',
    borderRadius: '5px',
    border: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  searchButton: {
    backgroundColor: '#007bff',
    color: 'white',
  },
  clearButton: {
    backgroundColor: '#6c757d',
    color: 'white',
  },
  icon: {
    marginRight: '8px',
  },
};

function SearchTool({ onSearch, onClearSearch }) {
  const [searchCriteria, setSearchCriteria] = useState({
    location: '',
    priceRange: '',
    techniques: [],
    accreditations: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: checked
        ? [...prevState[name], value]
        : prevState[name].filter((item) => item !== value),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchCriteria);
  };

  const handleClear = () => {
    setSearchCriteria({
      location: '',
      priceRange: '',
      techniques: [],
      accreditations: [],
    });
    onClearSearch();
  };

  return (
    <section className="search-tool" aria-labelledby="search-heading" style={styles.searchTool}>
      <form onSubmit={handleSubmit} aria-label="Search for hair transplant clinics" style={styles.form}>
        <div style={styles.inputGroup}>
          <label htmlFor="location" style={styles.label}>
            <FaSearch aria-hidden="true" style={styles.icon} />
            Location
          </label>
          <input
            type="text"
            id="location"
            name="location"
            placeholder="Enter city or country"
            value={searchCriteria.location}
            onChange={handleInputChange}
            aria-label="Search by location"
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label htmlFor="priceRange" style={styles.label}>
            <FaDollarSign aria-hidden="true" style={styles.icon} />
            Maximum Price
          </label>
          <input
            type="number"
            id="priceRange"
            name="priceRange"
            placeholder="Enter max price (e.g. 10000)"
            value={searchCriteria.priceRange}
            onChange={handleInputChange}
            aria-label="Search by maximum price"
            style={styles.input}
          />
        </div>
        <fieldset style={styles.checkboxGroup}>
          <legend style={styles.label}>
            <FaCut aria-hidden="true" style={styles.icon} /> Hair Transplant Techniques
          </legend>
          <div style={styles.checkboxContainer}>
            {['FUE', 'FUT', 'DHI', 'Robotic'].map((technique) => (
              <label key={technique} style={styles.checkbox}>
                <input
                  type="checkbox"
                  id={`technique-${technique}`}
                  name="techniques"
                  value={technique}
                  checked={searchCriteria.techniques.includes(technique)}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: '5px' }}
                />
                {technique}
              </label>
            ))}
          </div>
        </fieldset>
        <fieldset style={styles.checkboxGroup}>
          <legend style={styles.label}>
            <FaCertificate aria-hidden="true" style={styles.icon} /> Clinic Accreditations
          </legend>
          <div style={styles.checkboxContainer}>
            {['ABHRS', 'ISHRS', 'IAHRS'].map((accreditation) => (
              <label key={accreditation} style={styles.checkbox}>
                <input
                  type="checkbox"
                  id={`accreditation-${accreditation}`}
                  name="accreditations"
                  value={accreditation}
                  checked={searchCriteria.accreditations.includes(accreditation)}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: '5px' }}
                />
                {accreditation}
              </label>
            ))}
          </div>
        </fieldset>
        <div style={styles.buttonGroup}>
          <button type="submit" style={{ ...styles.button, ...styles.searchButton }}>
            Search Clinics
          </button>
          <button type="button" onClick={handleClear} style={{ ...styles.button, ...styles.clearButton }}>
            Clear Search
          </button>
        </div>
      </form>
    </section>
  );
}

export default SearchTool;