import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './BlogPost.css';

const BlogPost = ({ title, content, description, keywords }) => {
  const formatSlug = (text) => text.toLowerCase().replace(/\s+/g, '-');

  return (
    <div className="blog-post">
      <Helmet>
        <title>{title} | All Hair Clinics</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={`${title} | All Hair Clinics`} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${title} | All Hair Clinics`} />
        <meta name="twitter:description" content={description} />
        <link rel="canonical" href={`https://allhairclinics.com/blog/${formatSlug(title)}`} />
      </Helmet>
      <h1 className="visually-hidden">{title}</h1>
      <h2 className="blog-title">{title}</h2>
      <div className="blog-content">
        {content}
      </div>
      <div className="hidden-links">
        <Link to="/blog">All Blog Posts</Link>
        <Link to="/clinics">Hair Transplant Clinics</Link>
        <Link to="/about">About All Hair Clinics</Link>
        <Link to="/reviews">Hair Transplant Reviews</Link>
      </div>
    </div>
  );
};

export default BlogPost;