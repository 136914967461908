import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import SearchTool from '../components/SearchTool';
import ClinicList from '../components/ClinicList';
import clinicsDataPart1 from '../data/clinics_part1.json';
import clinicsDataPart2 from '../data/clinics_part2.json';
import { FaChevronRight } from 'react-icons/fa';
import './Home.css';

function Home() {
  const [clinicsData, setClinicsData] = useState([]);
  const [filteredClinics, setFilteredClinics] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const combinedClinicsData = [...clinicsDataPart1, ...clinicsDataPart2];
    setClinicsData(combinedClinicsData);
    console.log('Combined Clinics Data:', combinedClinicsData);

    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearch = (searchCriteria) => {
    console.log('Search criteria:', searchCriteria);

    const filtered = clinicsData.filter(clinic => {
      console.log('Checking clinic:', clinic);

      // Location match
      const locationMatch = searchCriteria.location === '' || 
        clinic.location.toLowerCase().includes(searchCriteria.location.toLowerCase());

      // Price match
      let priceMatch = true;
      if (searchCriteria.priceRange !== '') {
        const clinicPriceRange = clinic.priceRange || '';
        console.log('Clinic price range:', clinicPriceRange);
        const [clinicMinPrice, clinicMaxPrice] = clinicPriceRange.split(' - ').map(price => parseInt(price.replace(/[^0-9]/g, '')));
        const searchMaxPrice = parseInt(searchCriteria.priceRange);
        priceMatch = !isNaN(clinicMinPrice) && !isNaN(clinicMaxPrice) && !isNaN(searchMaxPrice) &&
                     clinicMinPrice <= searchMaxPrice && clinicMaxPrice >= searchMaxPrice;
        console.log('Price matching:', {
          clinicName: clinic.name,
          clinicMinPrice,
          clinicMaxPrice,
          searchMaxPrice,
          priceMatch
        });
      }

      // Techniques match
      const techniquesMatch = searchCriteria.techniques.length === 0 || 
        searchCriteria.techniques.some(tech => (clinic.techniquesUsed || []).includes(tech));

      // Accreditations match
      const accreditationsMatch = searchCriteria.accreditations.length === 0 || 
        searchCriteria.accreditations.some(accr => (clinic.accreditations || []).includes(accr));

      console.log('Clinic:', clinic.name, 'Matches:', { locationMatch, priceMatch, techniquesMatch, accreditationsMatch });

      return locationMatch && priceMatch && techniquesMatch && accreditationsMatch;
    });

    console.log('Filtered clinics:', filtered);
    setFilteredClinics(filtered);
    setSearchPerformed(true);
  };

  const handleClearSearch = () => {
    setFilteredClinics([]);
    setSearchPerformed(false);
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "HairCompare - Hair Transplant Clinic Comparison",
    "url": "https://allhairclinics.com/",
    "description": "Compare top hair transplant clinics based on location, price, techniques, and patient reviews. Find the best option for your hair restoration journey.",
    "potentialAction": {
      "@type": "SearchAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": "https://allhairclinics.com/search?q={search_term_string}"
      },
      "query-input": "required name=search_term_string"
    }
  };

  return (
    <>
      <Helmet>
        <title>HairCompare | Hair Transplant Clinic Comparison and Reviews</title>
        <meta name="description" content="Compare top hair transplant clinics worldwide. Find the best options based on location, price, techniques, and patient reviews. Make an informed decision for your hair restoration journey." />
        <link rel="canonical" href="https://allhairclinics.com/" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="HairCompare | Hair Transplant Clinic Comparison and Reviews" />
        <meta property="og:description" content="Compare top hair transplant clinics worldwide. Find the best options based on location, price, techniques, and patient reviews." />
        <meta property="og:image" content="https://allhairclinics.com/og-image.jpg" />
        <meta property="og:url" content="https://allhairclinics.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="HairCompare | Hair Transplant Clinic Comparison and Reviews" />
        <meta name="twitter:description" content="Compare top hair transplant clinics worldwide. Find the best options based on location, price, techniques, and patient reviews." />
        <meta name="twitter:image" content="https://allhairclinics.com/twitter-image.jpg" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <header className="header">
        <div className="header-content">
          <div className="logo-container">
            <img src="/favicon.png" alt="All Hair Clinics Logo" className="logo-image" />
            <span className="logo-text">All Hair Clinics</span>
          </div>
          <nav className="nav">
            <Link to="/" className="nav-link">Home</Link>
            <Link to="/about" className="nav-link">About</Link>
            <Link to="/clinics" className="nav-link">Clinics</Link>
            <Link to="/reviews" className="nav-link">Reviews</Link>
          </nav>
        </div>
      </header>
      <main>
        <section className="hero" style={{
          backgroundImage: `url('/man.png')`,
          backgroundPosition: `center ${scrollPosition * 0.5}px`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed'
        }}>
          <div className="hero-overlay"></div>
          <div className="hero-content">
            <h1 className="hero-title">Find Your Perfect Hair Transplant Clinic</h1>
            <p className="hero-subtitle">Compare top-rated clinics based on location, price, techniques, and patient reviews.</p>
            <button className="button" onClick={() => document.getElementById('search').scrollIntoView({ behavior: 'smooth' })} aria-label="Start your search">
              Start Your Search
            </button>
          </div>
        </section>
        <section id="search" className="section">
          <div className="section-content">
            <h2 className="section-title">Find Your Clinic</h2>
            <SearchTool onSearch={handleSearch} onClearSearch={handleClearSearch} />
          </div>
        </section>
        {searchPerformed && (
          <section className="section" aria-live="polite">
            <div className="section-content">
              <h2 className="section-title">Search Results</h2>
              <ClinicList clinics={filteredClinics} />
            </div>
          </section>
        )}
        <section className="section">
          <div className="section-content">
            <h2 className="section-title">Why Choose Us</h2>
            <div className="grid">
              <div className="card">
                <h3 className="card-title">Expert Comparisons</h3>
                <p className="card-text">We provide detailed comparisons of top hair transplant clinics to help you make an informed decision.</p>
                <Link to="/about" className="button button-inline" aria-label="Explore our expert hair transplant clinic comparisons">
                  Explore Clinics <FaChevronRight className="icon-right" aria-hidden="true" />
                </Link>
              </div>
              <div className="card">
                <h3 className="card-title">Verified Reviews</h3>
                <p className="card-text">Read authentic patient reviews and ratings to get a real perspective on each clinic's performance.</p>
                <Link to="/reviews" className="button button-inline" aria-label="Read verified patient reviews">
                  Read Reviews <FaChevronRight className="icon-right" aria-hidden="true" />
                </Link>
              </div>
              <div className="card">
                <h3 className="card-title">Comprehensive Information</h3>
                <p className="card-text">Get all the details you need, from pricing and techniques to accreditations and success rates.</p>
                <Link to="/clinics" className="button button-inline" aria-label="View comprehensive clinic information">
                  View Clinics <FaChevronRight className="icon-right" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;