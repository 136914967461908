import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import ClinicDetails from './pages/ClinicDetails';
import Clinics from './pages/Clinics';
import About from './pages/About';
import Reviews from './pages/Reviews';
import BlogList from './pages/blog/BlogList';
import BenefitsOfHairTransplants from './pages/blog/BenefitsOfHairTransplants';
import HairTransplantTurkeyGuide from './pages/blog/HairTransplantTurkeyGuide';
import HairTransplantConsultation from './pages/blog/HairTransplantConsultation';
import HairTransplantRecoveryGuide from './pages/blog/HairTransplantRecoveryGuide';
import HairTransplantCostComparison from './pages/blog/HairTransplantCostComparison';
import HairTransplantScience from './pages/blog/HairTransplantScience';
import HairTransplantMythsDebunked from './pages/blog/HairTransplantMythsDebunked';
import ChoosingRightHairTransplantClinic from './pages/blog/ChoosingRightHairTransplantClinic';
import './styles/global.css';
import './App.css';

function Header() {
  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo">All Hair Clinics</Link>
        <nav className="nav">
          <Link to="/about" className="nav-link">About Our Website</Link>
          <Link to="/clinics" className="nav-link">Clinics</Link>
          <Link to="/reviews" className="nav-link">Reviews</Link>
          <Link to="/blog" className="nav-link">Blog</Link>
          <Link to="/contact" className="nav-link">Contact</Link>
        </nav>
      </div>
    </header>
  );
}

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column">
          <h3 className="footer-title">All Hair Clinics</h3>
          <p>&copy; 2023 Hair Transplant Clinic Comparison. All rights reserved.</p>
        </div>
        <div className="footer-column">
          <h3 className="footer-title">Quick Links</h3>
          <Link to="/about" className="footer-link">About Hair Transplant Comparisons</Link>
          <Link to="/clinics" className="footer-link">Clinics</Link>
          <Link to="/reviews" className="footer-link">Reviews</Link>
          <Link to="/blog" className="footer-link">Blog</Link>
          <Link to="/contact" className="footer-link">Contact</Link>
        </div>
        <div className="footer-column">
          <h3 className="footer-title">Legal</h3>
          <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
          <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
        </div>
      </div>
    </footer>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/clinic/:clinicName" element={<ClinicDetails />} />
            <Route path="/about" element={<About />} />
            <Route path="/clinics" element={<Clinics />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/blog/benefits-of-hair-transplants" element={<BenefitsOfHairTransplants />} />
            <Route path="/blog/hair-transplant-turkey-guide" element={<HairTransplantTurkeyGuide />} />
            <Route path="/blog/hair-transplant-consultation" element={<HairTransplantConsultation />} />
            <Route path="/blog/hair-transplant-recovery-guide" element={<HairTransplantRecoveryGuide />} />
            <Route path="/blog/hair-transplant-cost-comparison" element={<HairTransplantCostComparison />} />
            <Route path="/blog/hair-transplant-science" element={<HairTransplantScience />} />
            <Route path="/blog/hair-transplant-myths-debunked" element={<HairTransplantMythsDebunked />} />
            <Route path="/blog/choosing-right-hair-transplant-clinic" element={<ChoosingRightHairTransplantClinic />} />
            <Route path="/contact" element={<div>Contact Page</div>} />
            <Route path="/privacy-policy" element={<div>Privacy Policy</div>} />
            <Route path="/terms-of-service" element={<div>Terms of Service</div>} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
