import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './Reviews.css';

const Reviews = () => {
  return (
    <div className="reviews-page">
      <Helmet>
        <title>Verified Hair Clinic Reviews | All Hair Clinics</title>
        <meta name="description" content="Explore verified reviews of hair clinics and hair transplantation services worldwide. Make an informed decision with authentic patient feedback and ratings." />
        <link rel="canonical" href="https://allhairclinics.com/reviews" />
      </Helmet>
      <h1 className="visually-hidden">Verified Hair Clinic Reviews</h1>
      <h2 className="page-title">About Us</h2>
      <div className="content-section">
        <p>Welcome to All Hair Clinics, your trusted source for verified reviews of hair clinics and hair transplantation services worldwide. We understand that choosing the right clinic for your hair restoration journey is a crucial decision. That's why we're dedicated to providing you with authentic patient reviews and ratings to give you a real perspective on each clinic's performance.</p>
      </div>
      <div className="content-section">
        <h3>Our Commitment to Authenticity</h3>
        <p>At All Hair Clinics, we believe in transparency and honesty. All our reviews are sourced from approved and trusted vendors like Trustpilot and are meticulously aggregated here. This ensures that you receive genuine feedback from real patients who have experienced the services firsthand. We do not endorse paid reviews; our goal is to present you with unbiased information to help you make an informed choice.</p>
      </div>
      <div className="content-section">
        <h3>Why Trust All Hair Clinics?</h3>
        <ul>
          <li><strong>Verified Patient Reviews:</strong> Gain insights from authentic experiences shared by patients who have undergone hair transplantation procedures.</li>
          <li><strong>Comprehensive Clinic Listings:</strong> Explore a wide range of clinics worldwide, each accompanied by detailed information and patient feedback.</li>
          <li><strong>Unbiased Information:</strong> As an independent platform, we are committed to delivering impartial reviews without any commercial influence.</li>
        </ul>
      </div>
      <div className="content-section">
        <h3>Join Our Community</h3>
        <p>We value the contributions of both patients and professionals in enriching our platform. If you have a review suggestion or wish to share your personal experience, please <Link to="/contact">reach out to us</Link>. Your insights can help others navigate their hair restoration journey with confidence.</p>
      </div>
      <div className="content-section">
        <h3>Stay Informed</h3>
        <ul>
          <li><strong>Latest Reviews:</strong> Keep up-to-date with the most recent patient feedback and clinic ratings.</li>
          <li><strong>Expert Advice:</strong> Access resources and articles from leading experts in the field of hair restoration.</li>
          <li><strong>Community Support:</strong> Connect with others who are on the same journey and share your experiences.</li>
        </ul>
      </div>
      <div className="content-section">
        <h3>Contact Us</h3>
        <p>Have questions or need assistance? We're here to help. <Link to="/contact">Contact us</Link> for more information or support.</p>
      </div>
      <div className="content-section">
        <p>At All Hair Clinics, we're more than just a review aggregator—we're a community dedicated to supporting you every step of the way in your hair restoration journey. Trust in authentic reviews. Trust in All Hair Clinics.</p>
      </div>
    </div>
  );
};

export default Reviews;