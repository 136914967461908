import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import clinicsPart1 from '../data/clinics_part1.json';
import clinicsPart2 from '../data/clinics_part2.json';
import './ClinicDetails.css';

const ClinicDetails = () => {
  const [clinic, setClinic] = useState(null);
  const { clinicName } = useParams();

  useEffect(() => {
    const allClinics = [...clinicsPart1, ...clinicsPart2];
    const foundClinic = allClinics.find(c => formatClinicName(c.name) === clinicName);
    setClinic(foundClinic);
  }, [clinicName]);

  const formatClinicName = (name) => {
    return name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  if (!clinic) {
    return <div>Loading...</div>;
  }

  return (
    <div className="clinic-details">
      <Helmet>
        <title>{`${clinic.name} | Hair Transplant Clinic Details`}</title>
        <meta name="description" content={`Learn more about ${clinic.name}, their techniques, pricing, and patient reviews for hair transplant procedures.`} />
        <link rel="canonical" href={`https://allhairclinics.com/clinic/${formatClinicName(clinic.name)}`} />
      </Helmet>
      <h1>{clinic.name}</h1>
      <div className="clinic-info">
        <p><strong>Location:</strong> {clinic.location}</p>
        <p><strong>Price Range:</strong> {clinic.priceRange}</p>
        <p><strong>Techniques:</strong> {clinic.techniquesUsed.join(', ')}</p>
      </div>
      <h2>Accreditations</h2>
      <p>{clinic.accreditations}</p>
      <h2>Patient Reviews</h2>
      <p>{clinic.patientReviews}</p>
      <h2>Website</h2>
      <a href={clinic.website} target="_blank" rel="noopener noreferrer">{clinic.website}</a>
    </div>
  );
};

export default ClinicDetails;