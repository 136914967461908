import React from 'react';
import { Link } from 'react-router-dom';
import './ClinicList.css';

function createSlug(name) {
  return name
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
}

function ClinicList({ clinics }) {
  return (
    <section className="clinic-list" aria-label="List of Hair Transplant Clinics">
      {clinics.length > 0 ? (
        <ul className="clinic-list-items">
          {clinics.map((clinic, index) => {
            const clinicSlug = createSlug(clinic.name);
            return (
              <li key={index} className="clinic-item">
                <h2 className="clinic-name">{clinic.name}</h2>
                <Link to={`/clinic/${clinicSlug}`} className="learn-more-btn">
                  Learn More
                </Link>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="no-results">No clinics found matching your criteria.</p>
      )}
    </section>
  );
}

export default ClinicList;